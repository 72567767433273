<template>
    <SendComment maxlength="250" :lesson="props.lesson" placeholder="Adicionar comentário..." button="Enviar" />
    <ul v-if="props.comments.length > 0" class="comments_box">
        <ShowComment v-for="comment in props.comments" :key="comment.id" :name="comment.name" :date="comment.created_at" :comment="comment.message" :status="comment.status">
            <Reply :lesson="props.lesson" :commentParent="comment.id" v-if="comment.status == 'published'" />
            <ul>
                <ShowComment class="mt-4" v-for="reply in comment.replies" :key="reply.id" :name="reply.name" :date="reply.created_at" :comment="reply.message" :isUser="isUser(reply.type)" :status="comment.status" />
            </ul>
        </ShowComment>
    </ul>
</template>

<script setup>
import ShowComment from '@/components/layout/course/comments/ShowComment';
import SendComment from '@/components/layout/course/comments/SendComment';
import Reply from '@/components/layout/course/comments/ReplyComment';

const props = defineProps({
    comments: {
        required: true
    },
    lesson: {
        required: true
    }
});

function isUser(r) {
    return (r == 'user') ? true : false;
}
</script>

<style scoped>
.comments_box {
    max-width: 800px;
}

ul {
    margin: 0;
    padding: 0;
}
</style>