<template>
    <div>
        <div class="d-flex align-items-center justify-content-center">
            <div class="bar">
                <div :style="['width: ' + percentage + '%']"></div>
            </div> 
            <span>{{ percentage }}%</span>
        </div>
        <p>
            {{ duration }}
            <svg width="4px" class="mx-1" fill="#646464" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512z"/></svg>
            {{ qtyLessons }} aulas
        </p>
    </div>
</template>

<script>
export default {
    props: {
        percentage: {
            required: true
        },
        duration: {
            required: true
        },
        qtyLessons: {
            required: true
        }
    }
}
</script>

<style scoped>
    .bar {
        background: #D9D9D9;
        width: 100%;
        height: 6px;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 15px;
    }

    .bar > div {
        background: #407FDD;
        height: 100%;
        transition: all 0.35s ease;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 13px;
        color:  #646464;
        line-height: 19.5px;
    }

    span {
        font-size: 13px;
    }

    svg {
        margin-top: -2px
    }
</style>