import axios from 'axios';

const Api = axios.create({
    // eslint-disable-next-line
    'baseURL': process.env.VUE_APP_API_URL,
    withCredentials: true,
    headers: {"Content-Type": "multipart/form-data"},
    timeout: 10000,
    timeoutErrorMessage: 'Não foi possível realizar essa operação, tente novamente mais tarde'
});

Api.interceptors.request.use(config => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

export default Api;