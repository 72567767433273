<template>
    <div class="form-group d-flex align-items-center d-flex justify-content-between">
        <label>{{ label }}</label>
        <label :for="id" class="switch" :class="[error ? 'danger-label' : '']">
            <input
                :id="id"
                type="checkbox"
                :checked="checked"
                @input="updateInput"
            />
            <span :class="'slider ' + [error ? 'danger-border' : '']"></span>
        </label>

        <span v-if="!!error" class="invalid-feedback d-block" role="alert">{{ error }}</span>
    </div>
</template>

<script>
import { useGlobalStore } from "@/store/global";

export default {
    emits: ["update:checked"],
    data() {
        return {
            global: useGlobalStore()
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },

        id: {
            type: String,
            required: true
        },

        page: {
            type: String,
            required: true
        },

        checked: {}
    },
    methods: {
        updateInput(event) {
            this.$emit('update:checked', event.target.checked);
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                delete this.global.errors[this.$props.page][this.$props.id];
            }
        },
    },
    computed: {
        error() {
            if(this.global.errors[this.$props.page] && this.global.errors[this.$props.page][this.$props.id]) {
                return this.global.errors[this.$props.page][this.$props.id];
            }
            return null;
        }
    }
};
</script>

<style scoped>
.switch {
  position: relative;
  width: 40px;
  height: 22px;
  cursor: pointer;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d7d7d7;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 60px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

label {
    color: #212529;
}

.dark label {
    color: #fff !important;
}
</style>