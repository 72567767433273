<template>
    <li :class="[isUser ? 'author' : '']">
        <hr/>
        <div class="d-flex w-100 align-middle">
            <div class="name_icon d-flex text-center justify-content-center flex-column">{{ firstLetter }}</div>
            <div class="top">
                <p>{{ name }}<span class="instructor" v-if="isUser"> - Instrutor</span></p>
                <span>Publicado {{ formatDate(date) }} <span v-if="status != 'publish'">- Pendente</span></span>
            </div>
        </div>
        <div class="left-space">
            <p class="mt-3">{{ comment }}</p>
            <slot></slot>
        </div>
    </li>
</template>

<script>
import { useGlobalStore } from '@/store/global';
export default {
    data() {
        return {
            global: useGlobalStore()
        }
    },
    props: {
        name: {
            required: true,
        },
        date: {
            required: true,
        },
        comment: {
            required: true
        },
        isUser: {
            required: false,
            default: false
        },
        status: {
            required: true,
        }
    },
    computed: {
        firstLetter() {
            return this.global.getFirstLetterName(this.name);
        }
    },
    methods: {
        formatDate(d) {
            return this.global.getFormatDate(d);
        }
    }
};
</script>

<style scoped>
hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 40px;
    color: #d7d7d7 !important;
    opacity: 1 !important;
}

li {
    list-style: none;
}

li:first-child > hr {
    display: none;
}

.name_icon {
    width: 30px;
    height: 30px;
    background: #646464;
    color: #fff;
    border-radius: 60px;
    user-select: none;
    margin-right: 15px;
    margin-top: 2px;
}

.top p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.top span {
    color: #A7A1A1;
    font-size: 13px;
    font-weight: 400;
}

.left-space {
    margin-left: 45px;
}

.author {
    border:1px #d7d7d7 solid;
    background: #F5F5F5;
    border-radius: 10px;
    padding: 15px;
}

.author p {
    margin-bottom: 0 !important;
}

.author .instructor {
    font-size: 16px;
}

.author hr {
    display: none;
}
</style>