<template>
    <div>
        <BaseInput maxlength="250" :placeholder="props.placeholder" :id="comment_id" :page="page" textarea v-model="inputs.message" />
        <div class="text-end mt-3">
            <BaseButton class="btn-primary" :disabled="disabled" @click="sendComment" style="width:200px">{{ props.button }}</BaseButton>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
const props = defineProps({
    placeholder:{
        required: false,
        default: null
    },
    button: {
        require: true,
        default: 'Enviar'
    },
    lesson: {
        required: true
    },
    commentParent: {
        required: false,
        default: 0
    }
});

const page = ref('content');

const disabled = ref(false);

const comment_id = computed(function(){
    return "comment_" + props.commentParent;
});

const inputs = ref({
    message: '',
    id_parent: (props.commentParent == 0) ? null : props.commentParent
});

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

import { useCourseStore } from "@/store/course";
const content = useCourseStore();

async function sendComment() {
    if (inputs.value.message === "") {
        global.tryLocalErrors(comment_id.value, 'O comentário não pode ficar em branco', page.value);
        return;
    }

    if (inputs.value.message.length > 250) {
        global.tryLocalErrors(comment_id.value, 'Comentário excede limite de caracteres', page.value);
        return;
    }

    disabled.value = true;

    content.trySendComment(page.value, inputs.value).then((r) =>{
        disabled.value = false;
        if(r) {
            inputs.value.message = '';
        }
    });
}

onMounted(() => {
    delete global.errors[page.value];
});
</script>

