<template>
    <div class="accordion">
        <div v-for="chapter in chapters" :key="chapter.id" class="accordion-item">
            <h2 class="accordion-header" :id="'panel' + chapter.id" @click="[chapter.type !== 'module' ? updateLesson(chapter.slug) : '']">
                <button v-if="chapter.type === 'module'" :class="'accordion-button ' + [keepOpen.includes(chapter.id) ? '' : 'collapsed']" type="button" data-bs-toggle="collapse" :data-bs-target="'#panel' + chapter.id + '-collapse'" :aria-expanded="[keepOpen.includes(chapter.id) ? 'true' : 'false']" :aria-controls="'panel' + chapter.id + '-collapse'">
                    <span>{{ chapter.title }}</span>
                    <p v-if="chapter.type === 'module'">{{ chapter.lessons.length }} aulas</p>
                </button>
                <button v-else class="accordion-button lesson_module" type="button">
                    <span>{{ chapter.title }}</span>
                </button>
            </h2>
            <div :id="'panel' + chapter.id + '-collapse'" :class="'accordion-collapse collapse ' + [keepOpen.includes(chapter.id) ? 'show' : 'collapsed']" :aria-labelledby="'panel' + chapter.id">
                <div class="accordion-body">
                    <ul>
                        <li v-for="lesson in chapter.lessons" :key="lesson.slug" :class="[selectedLesson == lesson.slug ? 'active' : '']" @click="updateLesson(lesson.slug)">
                            <svg v-if=(lesson.watched) xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                            <span>{{ lesson.title }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ["updateCurrentSelectedLesson", "changeNavMenuStatus"],
    data() {
        return {
            keepOpen: [],
        }
    },
    props: {
        chapters: {
            required: true
        },
        selectedChapter: {
            required: true
        },
        selectedLesson: {
            required: true
        }
    },
    mounted() {
        this.keepOpen.push(this.selectedChapter);
    },
    methods: {
        updateLesson(id) {
            this.$emit('changeNavMenuStatus', false);
            this.$emit('updateCurrentSelectedLesson', id);
        }
    },
    watch: {
        selectedChapter(r) {
            this.keepOpen.push(r);
        }
    }
}
</script>

<style scoped>
.accordion-item {
    border: 0;
    border-bottom: 1px #d7d7d7 solid;
    border-radius: 0 !important;
}

.accordion-item h2:hover {
    background-color: #F9F9F9;
}
.accordion-button:focus:not(:focus-visible), .accordion-button:focus:focus, .accordion-button:not(.collapsed) {
    outline: none !important;
    box-shadow: none !important;
}

.accordion-button {
    color: #595959 !important;
    font-weight: 500;
    position: relative;
    z-index: 1;
    background: transparent !important;
    display: block;
    padding: 1rem 1.5rem
}

.accordion-button:not(.collapsed) {
    background: #fff;
}

.accordion-button::after {
    width: 15px;
    height: 15px;
    background-size: cover;
    position: absolute;
    right: 17px;
    top:25px;
}

.lesson_module::after{
    display:none!important;
}

.accordion-button:not(.collapsed)::after {
    background: var(--bs-accordion-btn-icon);
}

.accordion-button > p {
    font-size: 14px;
    color: #8B8B8B;
    font-weight: 400;
    margin: 0;
    margin-top: 2px;
} 

.accordion-collapse {
    z-index: 2;
    position: relative;
}

.accordion-body {
    padding: 0;
}

.accordion-item:last-child {
    border-bottom: 0;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
    padding: 1rem 1.5rem;
    color: #646464;
    cursor: pointer;
}

li:hover {
    background: #F9F9F9;
}

li.active {
    background: #F3F3F3;
}

li svg {
    width: 16px;
    fill: #0AAB1A;
    margin-top: -2px;
    margin-right: 10px;
}
</style>