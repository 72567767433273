<template>
    <div class="min-height" v-if="!pageIsLoading && chapters">
        <TheHeader :isCourse="true" fluid dark_select :logo="content.logo" :isMobile="isMobile" :navMenuIsOpen="navMenuIsOpen" @changeNavMenuStatus="changeNavMenuStatus" />
        <div class="content">
            <div v-if="!contentBoxIsLoading && currentLesson">
                <div v-if="currentLesson.content.video" class="video_container">
                    <div class="video_box">
                        <div>
                            <iframe :src="currentLesson.content.video" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div class="descriptions_content">
                    <div>
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <button :class="'nav-link ' + [currentNavTab == 'content' ? 'active' : '']" data-bs-toggle="tab"
                                    data-bs-target="#content-tab" type="button" role="tab"
                                    aria-controls="content-tab" :aria-selected="[currentNavTab == 'content' ? 'true' : 'false']"
                                    @click="changeCurrentNav('content')">
                                    <div class="video_topics_titles">
                                        <p>Conteúdo</p>
                                    </div>
                                </button>
                            </li>
                            <li class="nav-item">
                                <button :class="'nav-link ' + [currentNavTab == 'comments' ? 'active' : '']" id="comments" data-bs-toggle="tab"
                                    data-bs-target="#comments-tab" type="button" role="tab"
                                    aria-controls="comments-tab" :aria-selected="[currentNavTab == 'comments' ? 'true' : 'false']"
                                    @click="changeCurrentNav('comments')">
                                    <div class="video_topics_titles">
                                        <p>Comentários</p>
                                    </div>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <BaseStarRating class="rating" v-if="!mobileStars" :rating="currentLesson.rating" :userRating="currentLesson.user_rating" @updateRating="updateRating" />
                    <div class="paginate">
                        <div class="previous_arrow" @click="prevLesson">
                            <svg class="arrows_navigation_svg prev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                        </div>
                        <div class="next_arrow" @click="nextLesson">
                            <svg class="arrows_navigation_svg next" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                        </div>
                    </div>
                </div>
                <div class="tab-content">
                    <div :class="'tab-pane fade ' + [currentNavTab == 'content' ? 'show active' : '']" id="content-tab" role="tabpanel" aria-labelledby="content"
                        tabindex="0">
                        <div>
                            <BaseStarRating v-if="mobileStars" class="stars_mobile" :rating="currentLesson.rating" :userRating="currentLesson.user_rating" @updateRating="updateRating" />
                            <h5 class="mb-3">{{ currentLesson.title }}</h5>
                            <div v-html="currentLesson.content.text"></div> 
                            <h6 v-if="currentLesson.content.questions">{{ currentLesson.content.questions.length }} questões</h6>
                            <template v-if="currentLesson.content.questions">
                                <div v-for="(question, key) in currentLesson.content.questions" :key="key">
                                    <h6><b>Questão {{ (key + 1) }}</b></h6>
                                    <p>{{ question.title }}</p>
                                    <div v-for="(answer, key_answer) in question.answers" :key="key_answer">
                                        <BaseCheckBox :id="'question_'+ (key + 1) + '_answer_' + (key_answer + 1)" page="TheHome" :label="answer.title" :class="'question_' + (key+1)" @click="switchAnswer('question_'+ (key + 1) + '_answer_' + (key_answer + 1))"/>
                                    </div>
                                </div>
                            </template>
                            <div class="conclude-box mt-4">
                                <button :class="[watched_button_loading == false && currentLesson.watched == 1 ? 'success' : '']" :disabled="watched_button_loading == true" @click="watched(currentLesson.slug, currentLesson.watched)">
                                    <svg v-if="watched_button_loading == false && currentLesson.watched == 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-v-6383d67d=""><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" data-v-6383d67d=""></path></svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-v-6383d67d=""><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" data-v-6383d67d=""></path></svg>
                                    <span v-if="currentLesson.watched">Aula concluída</span>
                                    <span v-else>Concluir aula</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div :class="'tab-pane fade ' + [currentNavTab == 'comments' ? 'show active' : '']" id="comments-tab" role="tabpanel" aria-labelledby="comments">
                        <div class="description_invisible">
                            <Comments :comments="currentLesson.comments" :lesson="currentLesson.slug" />
                        </div>
                    </div>
                </div>
            </div>
            <TheLoading v-else></TheLoading>
        </div>
        <aside :class="[isMobile == true ? 'mobile' : ''] + ' ' + [navMenuIsOpen == true ? 'open' : '']">
            <Progress :qtyLessons="listLessons.length" :title="content.title" :duration="content.duration" :percentage="content.percentage" />
            <Accordion :chapters="chapters" :selectedChapter="selectedChapter" :selectedLesson="selectedLesson" @changeNavMenuStatus="changeNavMenuStatus" @updateCurrentSelectedLesson="updateCurrentSelectedLesson" />
        </aside>
    </div>
    <TheLoading v-else></TheLoading>
</template>

<script setup>
    import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue";
    import TheLoading from '@/components/layout/includes/TheLoading';
    import TheHeader from "@/components/layout/includes/TheHeader";
    import Progress from "@/components/layout/course/InfoProgressContent";
    import Accordion from "@/components/layout/course/AccordionContent";
    import BaseCheckBox from "@/components/ui/BaseCheckBox"
    import BaseStarRating from '@/components/ui/BaseStarRarting/BaseRating';
    import Comments from '@/components/layout/course/CommentsContent';

    import { useRoute, useRouter } from "vue-router";
    const route = useRoute();

    const order_key = computed(function () {
        return route.params.code;
    });

    const product = computed(function() {
        return route.params.product;
    })

    const lesson = computed(function () {
        return route.params.lesson;
    });

    watch(lesson, function (curValue) {
        updateCurrentSelectedLesson(curValue);
    });

    const currentNavTab = ref('content');
    function changeCurrentNav(r) {
        currentNavTab.value = r;
    }

    const pageIsLoading = ref(true);
    const contentBoxIsLoading = ref(true);

    import { useCourseStore } from "@/store/course";

    const content = useCourseStore();

    const chapters = computed(function () {
        if(content.chapters.length){
            return content.chapters;
        } else{
            return false;
        }
    });

    const currentLesson = computed(function () {
        return content.currentLesson;
    });

    const listLessons = computed(function () {
        return content.listLessons;
    });

    const navMenuIsOpen = ref(false);
    function changeNavMenuStatus(r) {
        navMenuIsOpen.value = r;
    }

    onMounted(() => {
        if(sessionStorage.getItem('darkMode') == 1) {
            document.body.classList.add('dark');
        }

        content.order = order_key.value;
        content.product = product.value;
        content.selectedLesson = lesson.value;
        content.tryContent().then((r) => {
            if(r) {
                let countLessons = 0;

                if(!content.chapters.length){
                    router.push(router.options.history.state.back)
                    return;
                } else {
                    for(let i = 0; i < content.chapters.length; i++){
                        if(content.chapters[i].type === 'module'){
                            if(content.chapters[i].lessons.length !== 0){
                                countLessons = countLessons + 1
                            }
                        } else if(content.chapters[i].type !== 'module') {
                            countLessons = countLessons + 1;
                        }
                    }
                }

                if(countLessons === 0){
                    router.push(router.options.history.state.back)
                    return;
                }

                pageIsLoading.value = false;
                content.selectedChapter = content.getSelectedChapterId();
                window.addEventListener("resize", screenSize);
                screenSize();
                content.tryLesson().then((res) => {
                    if(res) {
                        contentBoxIsLoading.value = false;
                        content.tryComments();
                    }
                });
            }
        });
    });

    onBeforeUnmount(() => {
        document.body.classList.remove('dark');
    });

    function updateCurrentSelectedLesson(id) {
        if(content.selectedLesson != id) {
            content.selectedLesson = id;
            content.selectedChapter = content.getSelectedChapterId();
            updateLesson();
        }
    }

    const router = useRouter();

    function updateLesson() {
        contentBoxIsLoading.value = true;
        content.tryLesson().then(() => {
            contentBoxIsLoading.value = false;
            currentNavTab.value = 'content';
            content.tryComments();
        });
        router.push('/course/' + order_key.value + '/' + product.value + '/' + content.selectedLesson);
    }

    const isMobile = ref(false);
    const mobileStars = ref(false);

    function screenSize() {
        if(!pageIsLoading.value) {
            let w = window.innerWidth;
            isMobile.value = (w < 981) ? true : false;
            mobileStars.value = (w < 660) ? true : false;
        }
    }

    const selectedChapter = computed(function () {
        return content.selectedChapter;
    });

    const selectedLesson = computed(function () {
        return content.getSelectedLession();
    });

    function prevLesson() {
        let prev_lesson = content.getSelectedLessonKey() - 1;
        if(prev_lesson >= 0) {
            prev_lesson = content.getLessonId(prev_lesson);
            updateCurrentSelectedLesson(prev_lesson);
        }
    }

    function nextLesson() {
        let next_lesson = content.getSelectedLessonKey() + 1;
        if(next_lesson < content.listLessons.length) {
            next_lesson = content.getLessonId(next_lesson);
            updateCurrentSelectedLesson(next_lesson);
        }
    }

    function switchAnswer(answer_selected){
        let getAllAnswers = document.querySelectorAll('.question_' + answer_selected.split('_')[1] + ' input')
        for(let i = 0; i < getAllAnswers.length; i++){
            if(getAllAnswers[i].id !== answer_selected){
                getAllAnswers[i].checked = false;
            }
        }
    }

    const watched_button_loading = ref(false);

    function watched(slug, status) {
        watched_button_loading.value = true;
        content.tryChangeWatched(slug, status).then(() => {
            watched_button_loading.value = false;
        });
    }

    function updateRating(r) {
        content.tryChangeRating(r);
    }
</script>

<style scoped>
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    header {
        width: 100%;
        position: fixed;
        background: #fff;
        border-bottom: 1px #d7d7d7 solid;
        z-index: 99;
    }

    .content {
        padding: 77px 0px 0px 0px;
        width: 75%;
    }

    aside {
        position: fixed;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 2;
        background: #fff;
        width: 25%;
        border-left: 1px solid #d7d7d7;
        margin-top: 79px;
        padding-bottom: 79px;
        overflow: auto;
    }

    aside.mobile {
        left: -300px;
        transition: left .4s;
        border-right: 1px solid #d7d7d7;
    }

    aside.mobile.open {
        left: 0;
    }

    .box {
        background: #fff;
        border: 1px #d7d7d7 solid;
        border-radius: 8px;
    }

    .video_box {
        max-width: 950px;
        margin: 0 auto;
    }

    .video_box > div {
        padding: 57% 0px 0 90px;
        position: relative;
    }

    .video_div_box .container {
        margin-top: 15px;
    }

    .descriptions_content {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 0px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav-item .active {
        -webkit-box-shadow: inset 0px -3px 0px 0px #646464;
        -moz-box-shadow: inset 0px -3px 0px 0px #646464;
        box-shadow: inset 0px -3px 0px 0px #646464;
    }

    .nav-tabs {
        border: none !important;
        --bs-nav-tabs-border-width: 0px !important;
        margin-bottom: -2px;
        height: 51px;
    }

    .descriptions_content p {
        font-size: 15px;
        color: #646464;
        margin: 0 !important;
        font-weight: 400;
        user-select: none;
    }

    .descriptions_content>.row {
        margin-bottom: -2px;
    }

    .video_topics_titles {
        cursor: pointer;
        font-weight: 300;
        letter-spacing: 0px;
    }

    .nav-tabs .nav-link {
        padding: 15px 15px 14px 15px;
        transition: none !important;
    }

    .next_arrow,
    .previous_arrow {
        color: #646464;
        display: inline-block;
        cursor: pointer;
        font-weight: 300;
        padding: 14px 17px 14px 17px;
        user-select: none;
    }

    .next_arrow:hover,
    .previous_arrow:hover {
        background: #F3F3F3;
    }

    .arrows_navigation_svg {
        width: 15px;
        fill: #646464;
    }

    .classe_main_title {
        font-size: 18px;
        color: #646464;
    }

    .accordion-item {
        padding: 15px 0px 15px 0px;
        border-radius: 0px !important;
        border-right: none;
    }

    .accordion-body {
        padding-left: 0px;
        padding-right: 0px;
    }

    .classes_title_div[aria-expanded="true"] p {
        display: none;
    }

    .classes_title_div {
        padding: 5px 15px 0px 15px;
    }

    .classes_quantity {
        font-size: 13px;
        color: #8b8b8b;
    }

    .tab-pane {
        padding: 20px 15px;
        padding-bottom: 0px !important;
    }

    .tab-content {
        max-width: 950px;
        margin: 0 auto;
    }

    .paginate {
        float: right;
        margin-top: 0px;
    }

    .progress-on-nav.tab-pane {
        padding: 0 !important;
    }

    .video_container {
        background-color: #171717;
    }

    .conclude-box {
        display: inline-block;
    }

    .conclude-box > button {
        width: 165px;
        padding: 10px 15px;
        border: 1px solid #d7d7d7;
        border-radius: 4px;
        color: #595959;
        text-align: left;
        background: #fff;
    }

    .conclude-box > button:hover {
        background-color: #ececec;
    }

    .conclude-box > button.success {
        background: #198754;
        color: #fff;
        border: 1px solid #198754;
    }

    .conclude-box > button > span {
        vertical-align: bottom;
        color: #595959 !important;
    }

    .conclude-box > button:disabled {
        filter: opacity(0.7);
    }

    .conclude-box > button > svg {
        width: 16px;
        margin-right: 10px;
        fill: #595959;
    }

    .conclude-box > button.success svg {
        fill: #fff !important
    }

    .conclude-box > button.success span {
        color: #fff !important;
    }

    .rating {
        float: right;
        margin-top: 0px;
        margin-left: auto;
        margin-right: 20px;
    }

    .stars_mobile {
        margin-bottom: 20px;
    }

    @media (max-width: 1200px) {
        aside {
            width: 300px;
        }

        .content {
            width: calc(100% - 300px);
        }
    }

    @media (max-width: 981px) {
        .content {
            width: 100%;
        }

        .nav-tabs {
            overflow-x: auto;
            overflow-y:hidden;
            flex-wrap: nowrap;
        }

        .nav-tabs .nav-link {
            white-space: nowrap;
        }

        .nav-item {
            border-right: 1px solid #d9d9d9
        }

        .nav-item:last-child {
            border-right: 0;
        }
    }

    @media (max-width: 320px) {
        aside {
            width: 100%;
        }
    }
</style>

<style>
    .dark * {
        border-color: #21262d !important;
        color: #c9d1d9 !important;
    }

    .dark .accordion-item {
        background: #0d1117 !important;
    }

    .dark button.nav-link.active {
        background: #161b22 !important;
    }

    .dark li.active {
        background: #161b22 !important;
    }

    .dark .accordion-body li:hover {
        background: #232a35 !important;
    }

    .dark .accordion-body li:hover > .nav-link {
        box-shadow: inset 0px -3px 0px 0px #d7d7d7;
    }

    .dark {
        background: #010409;
        color: #c9d1d9 !important;
    }

    .dark header.py-4 {
        background: #161b22;
    }

    .dark aside {
        background: #0d1117 !important;
    }

    .dark .loader_body {
        background: #232a35 !important
    }

    .dark .menu_drop_account button:hover, .dark .menu_drop_account button:focus {
        -webkit-box-shadow: 0px 0px 0px 5px rgba(75,75,75,1);
        -moz-box-shadow: 0px 0px 0px 5px rgba(75,75,75,1);
        box-shadow: 0px 0px 0px 5px rgba(75,75,75,1);
    }

    .dark .next_arrow:hover,
    .dark .previous_arrow:hover {
        background: #232a35 !important;
    }

    .dark .bar {
        background: #646464;
    }

    .dark .video_container {
        background-color: #03060a;
    }

    .dark .dropdown-menu.show {
        background-color: #161b22 !important;
    }

    .dark .dropdown-menu.show strong {
        color: #fff !important;
    }

    .dark .dropdown-menu.show .dropdown-item {
        color:#fff !important;
        fill: #fff !important
    }

    .dark .dropdown-menu.show .dropdown-item:hover {
        background: #0c1117 !important
    }

    .dark .conclude-box > button {
        background: #006837;
    }

    .dark .conclude-box > button:hover {
        background:#137144;
    }

    .dark .conclude-box > button > span {
        color: #c9d1d9 !important
    }

    .dark .conclude-box > button > svg {
        fill: #c9d1d9 !important
    }

    .dark .paginate svg {
        fill: #c9d1d9 !important;
    }
    .dark .accordion-item h2:hover  {
        background: #232a35 !important;
    }
</style>