<template>
    <div>
        <span>{{ rating }}</span>
        <star number="1" :rating="userRating" @starSelected="click" @sendHoverOnStar="sendHoverOnStar" :hoverStar="hoverOnStar" />
        <star number="2" :rating="userRating" @starSelected="click" @sendHoverOnStar="sendHoverOnStar" :hoverStar="hoverOnStar" />
        <star number="3" :rating="userRating" @starSelected="click" @sendHoverOnStar="sendHoverOnStar" :hoverStar="hoverOnStar" />
        <star number="4" :rating="userRating" @starSelected="click" @sendHoverOnStar="sendHoverOnStar" :hoverStar="hoverOnStar" />
        <star number="5" :rating="userRating" @starSelected="click" @sendHoverOnStar="sendHoverOnStar" :hoverStar="hoverOnStar" />
    </div>
</template>

<script>
import Star from '@/components/ui/BaseStarRarting/BaseStar';
export default {
    emits: ["updateRating"],
    data() {
        return {
            hoverOnStar: false
        }
    },
    components: {
        Star
    },
    props: {
        rating: {},
        userRating: {}
    },
    methods: {
        click(r) {
            this.$emit('updateRating', r);
        },

        sendHoverOnStar(r) {
            this.hoverOnStar = r;
        }
    }

}
</script>

<style scoped>
svg:hover {
    fill: #ffc400 !important;
}

span {
    color: #646464;
    vertical-align: middle;
    text-align: right;
    margin-right: 10px;
    display: inline-block;
}

.dark svg {
    fill: #d7d7d7;
}

svg:hover ~ svg > path {
    fill: #ffdc00 !important;
}
</style>