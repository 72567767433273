<template>
    <div class="p-4 info">
        <h1>{{ title }}</h1>
        <ProgressBar :percentage="percentage" :duration="duration" :qtyLessons="qtyLessons" />
    </div>
</template>
<script>
import ProgressBar from '@/components/layout/course/ProgressBar';
export default {
    components: {
        ProgressBar
    },
    props: {
        qtyLessons: {
            required: true
        },
        title: {
            required: true
        },
        percentage: {
            required: true
        },
        duration: {
            required: true
        }
    }
}
</script>

<style scoped>

    .info {
        border-bottom: 1px solid #d7d7d7;
        color: #646464 !important;
    }

    h1 {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
    }
</style>