<template>
    <a v-if="!reply" @click.prevent="change" href="#">Responder</a>
    <SendComment :lesson="lesson" :commentParent="commentParent" v-else placeholder="Adicionar resposta..." button="Responder" />
</template>

<script>
import SendComment from '@/components/layout/course/comments/SendComment';
export default {
    components: {
        SendComment
    },
    data(){
        return {
            reply: false,
        }
    },
    props: {
        placeholder:{
            required: false,
            default: null
        },
        lesson: {
            required: true
        },
        commentParent: {
            required: false,
            default: null
        }
    },
    methods: {
        change() {
            this.reply = true;
        }
    }
}
</script>

<style scoped>
.dark a {
   color: #0d6efd !important;
}
</style>