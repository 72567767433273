<template>
    <TheHeader />
    <div class="w-100">
        <div class="container-fluid">
            <div class="container">
                <div class="mt-2 mb-4">
                    <h2 v-if="userFistName.length > 0">
                        Olá, {{ userFistName }}.
                    </h2>
                    <h2 v-else>Olá, ...</h2>
                    <p>Veja seus cursos abaixo.</p>
                </div>

                <ul v-if="coursesList">
                    <li v-for="course in coursesList" :key="course.order_key" :class="[courses.courses.length == 1 ? 'course_banner' : '']">
                        <div class="w-100">
                            <div class="row">
                                <div class="col-md-auto image">
                                    <img :src="course.image" />
                                </div>
                                <div class="col-md-7">
                                    <div>
                                        <p class="title">{{ course.title }}</p>
                                        <p class="date">Inscrito em {{formatDate(course.enrolled_in) }}</p>
                                    </div>
                                    <ProgressBar :percentage="Math.round(course.percentage)" :duration="getDuration(course.duration)" :qtyLessons="course.lessons" />
                                </div>
                                <div class="col d-flex align-items-center" style="margin-top:30px">
                                    <BaseButton class="w-100 btn-primary" link :to="'/course/' + course.order_key + '/' + course.id">
                                        <span>Acessar</span>
                                        <svg class="arrows_navigation_svg next" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                                    </BaseButton>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <router-view></router-view>
    <div class="loader_body" v-show="isLoading">
        <div></div>
    </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import TheHeader from "@/components/layout/includes/TheHeader";
import ProgressBar from '@/components/layout/course/ProgressBar';

import { useAuthStore } from "@/store/auth";
const loggedUser = useAuthStore();

const userFistName = computed(function () {
    const userNameArray = loggedUser.name.split(" ");
    return userNameArray[0];
});

const isLoading = ref(true);

import { useCourseStore } from "@/store/course";
const courses = useCourseStore();

onMounted(() => {
    courses.tryCourses().then(() => {
        isLoading.value = false;
    })
});

const coursesList = computed(function () {
    return courses.courses;
});

function getDuration(min) {
    return courses.getDuration(min);
}

import { useGlobalStore } from "@/store/global";
const global = useGlobalStore();

function formatDate(date) {
    return global.getFormatDate(date)
}

</script>

<style scoped>
h2 {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    color: #414951;
    font-weight: 600;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

li {
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 25px;
}

li:last-child {
    margin-bottom: 0px;
}

li img {
    height: 100px;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
}

li svg {
    width: 15px;
    fill: #fff;
}

li span {
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
}

li .title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

li .date {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color:  #646464;
    line-height: 19.5px;
}

@media screen and (max-width: 768px) {
    li .image {
        text-align: center;
        margin-bottom: 20px;
    }

    li .title {
        overflow: initial;
        white-space: initial;
        line-height: 25px;
        margin-bottom: 5px;
    }
}
</style>