import { createRouter, createWebHistory } from "vue-router";

//LOGIN PAGES
import TheLogin from '@/pages/login/TheLogin';
import TheForgotPassword from '@/pages/login/TheForgotPassword';
import TheLoginWithOutPassword from '@/pages/login/TheLoginWithOutPassword';


//PAGES
import TheHome from '@/pages/home/TheHome';
import TheNotFound from '@/pages/errors/TheNotFound';
import TheAccount from '@/pages/account/TheAccount';
import TheCoursePage from '@/pages/course/TheCourse'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: TheHome,
            meta: {
                title: 'Pedidos - Fullsale',
                requiresAuth: true,
                gTag: false
            }
        },
        {
            path: '/course/:code/:product/:lesson?',
            component: TheCoursePage,
            meta: {
              title: '',
              requiresAuth: true,
              gTag: false
            }
        },
        {
            path: '/account',
            component: TheAccount,
            meta: {
                title: 'Meus Dados - Fullsale',
                requiresAuth: true,
                gTag: false
            }
        },
        {
            path: '/login',
            component: TheLogin,
            meta: {
                title: 'Login - Fullsale',
                requiresAuth: false,
                gTag: true
            }
        },
        {
            path: '/forgot-password/:code?',
            component: TheForgotPassword,
            props: true,
            meta: {
                title: 'Recuperar senha - Fullsale',
                requiresAuth: false,
                gTag: true
            }
        },
        {
            path: '/login-without-password',
            component: TheLoginWithOutPassword,
            meta: {
                title: 'Login sem senha - Fullsale',
                requiresAuth: false,
                gTag: true
            }
        },
        {
            path: '/:notFound(.*)',
            component: TheNotFound,
            meta: {
                title: 'Página não encontrada - Fullsale',
                requiresAuth: false,
                gTag: false
            }
        }
    ]
});
export default router;