<template>
    <svg @mousemove="getPosition" @mouseleave="leave" @click="click" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <linearGradient :id="'startrating-' + number" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop :offset="offset" style="stop-color:rgb(245 199 3);stop-opacity:1" />
            <stop :offset="offset" style="stop-color:rgb(221,221,221);stop-opacity:1" />
        </linearGradient>
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path :fill="'url(#startrating-' + number + ')'" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
    </svg>
</template>

<script>
export default {
    emits: ["starSelected", "sendHoverOnStar"],

    data() {
        return {
            offset: 0,
            stars: 0
        }
    },

    props: {
        rating: {},
        number: {},
        hoverStar: {}
    },

    computed: {
        isSelected() {
            if(this.rating >= this.number) {
                return true;
            }else {
                return false;
            }
        },

        fillStar() {
            if(this.hoverStar === false) {
                if(this.rating >= this.number) {
                    return '100%';
                }else{
                    return '0%';
                }
            }else {
                if(this.hoverStar >= this.number) {
                    return '100%';
                }else{
                    return '0%';
                }
            }
        }
    },

    watch: {
        hoverStar() {
            this.offset = this.fillStar;
        },
        rating() {
            this.offset = this.fillStar;
        }
    },

    mounted() {
        this.offset = this.fillStar;
    },

    methods: {
        getPosition($event) {
            let starWidth = (92 / 100) * 16;
            const offset = (this.rtl) ? Math.min($event.offsetX, 45) : Math.max($event.offsetX, 1);
            let position = Math.round((100 / starWidth) * offset)
            if(Math.min(position, 100) > 5) {
                this.offset = '100%';
                this.stars = this.number;
            }else {
                this.offset = '0%';
            }
            this.$emit('sendHoverOnStar', this.number);
        },

        leave() {
            this.$emit('sendHoverOnStar', false);
            this.offset = this.fillStar;
        },

        click() {
            this.$emit('starSelected', this.stars);
        }
    }

}
</script>

<style scoped>
svg {
    fill: #b0b0b0;
    width: 22px;
    padding-right: 5px;
    transition: all 400ms ease-in-out;
    user-select: none;
    cursor: pointer;
    margin-top: -2px;
}

</style>