<template>
    <div class="ham-box">
        <input 
        type="checkbox"
        class="check"
        :checked="status"
        @input="changeStatus"
        />
        <div class="ham-menu">
            <svg width="17px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 88C0 74.75 10.75 64 24 64H424C437.3 64 448 74.75 448 88C448 101.3 437.3 112 424 112H24C10.75 112 0 101.3 0 88zM0 248C0 234.7 10.75 224 24 224H424C437.3 224 448 234.7 448 248C448 261.3 437.3 272 424 272H24C10.75 272 0 261.3 0 248zM424 432H24C10.75 432 0 421.3 0 408C0 394.7 10.75 384 24 384H424C437.3 384 448 394.7 448 408C448 421.3 437.3 432 424 432z"/></svg>
        </div>
    </div>
</template>

<script>
export default {
    emits: ["changeStatus"],
    data() {
        return {}
    },
    props: {
        status: {}
    },
    methods: {
        changeStatus(event) {
            this.$emit('changeStatus', event.target.checked);
        },
    }
};
</script>

<style scoped>
.ham-box {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
}

.check {
    height: 55px;
    width: 45px;
    opacity: 0;
    z-index: 3;
    position: absolute;
    top: 11px;
    left: 8px;
}

.check:hover {
    cursor: pointer;
}

.ham-menu {
    height: 19px;
    width: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 0.25em;
}

.dark .ham-menu svg {
    fill: #c0c0c0;
}
</style>