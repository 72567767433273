import { defineStore } from "pinia";
import Api from "@/api";
import { useGlobalStore } from "@/store/global";

// TO ACCESS ROUTER ON PINIA
// this.router. ...
// TO ACCESS ROUTER ON PINIA

export const useCourseStore = defineStore ('course', {
    state: () => ({
        courses: {},
        product: null,
        order: null,
        title: null,
        percentage: null,
        duration: null,
        logo: null,
        chapters: {},
        currentLesson: {},
        listLessons: [],
        selectedChapter: null,
        selectedLesson: null
    }),
    
    actions: {
        async tryCourses() {
            try {
                const response = await Api.get('api/courses');
                this.courses = response.data.data;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryContent() {
            try {

                const response = await Api.get('api/orders/' + this.order + '/content/' + this.product);
                this.chapters = response.data.data.content;
                this.title = response.data.data.title;
                this.logo = response.data.data.store.logotipo;
                this.listLessons = [];
                if(this.chapters.length){
                    for (var i = 0; i < this.chapters.length; i++) {
                        if(this.chapters[i].type === 'module'){
                            for (var l = 0; l < this.chapters[i].lessons.length; l++) {
                                this.listLessons.push({lesson: this.chapters[i].lessons[l].slug, chapter: this.chapters[i].id});
                            }
                        } else {
                            this.listLessons.push({lesson: this.chapters[i].slug, chapter: this.chapters[i].id});
                        }
                    }
                }else {
                    this.chapters = {}
                }
                
                this.duration = this.getDuration(response.data.data.duration);
                this.getCheckPercentage();
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryLesson() {
            try {
                const response = await Api.get('api/orders/' + this.order + '/content/' + this.product + '/lessons/' + this.getSelectedLession());
                this.currentLesson = response.data.data;
                this.currentLesson.comments = {};
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryChangeWatched(slug, status) {
            let n_status = (status) ? 0 : 1;
            try {
                await Api.post('api/orders/' + this.order + '/content/' + this.product + '/lessons/' + this.getSelectedLession() + '/read', { status: n_status});

                for (var i = 0; i < this.chapters.length; i++) {
                    if(this.chapters[i].type === 'module'){
                        for (var l = 0; l < this.chapters[i].lessons.length; l++) {
                            if(this.chapters[i].lessons[l].slug == slug) {
                                this.chapters[i].lessons[l].watched = n_status;
                                this.currentLesson.watched = n_status;
                                break;
                            }
                        }
                    } else {
                        if(this.chapters[i].slug !== undefined){
                            if(this.chapters[i].slug === slug){
                                this.chapters[i].watched = n_status;
                                this.currentLesson.watched = n_status
                            }
                        }
                    }
                }

                this.getCheckPercentage();

                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryChangeRating(rating) {
            this.currentLesson.user_rating = rating;
            try {
                const response = await Api.post('api/orders/' + this.order + '/content/' + this.product + '/lessons/' + this.getSelectedLession() + '/rating', { rating: rating});
                this.currentLesson.rating = response.data.rating_average;

                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async tryComments() {
            try {
                const response = await Api.get('api/orders/' + this.order + '/content/' + this.product + '/lessons/' + this.getSelectedLession() + '/comments');
                this.currentLesson.comments = response.data.data;

                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error);
                return false;
            }
        },

        async trySendComment(page, params) {                       
            try {
                await Api.post('api/orders/' + this.order + '/content/' + this.product + '/lessons/' + this.getSelectedLession() + '/comments', params);
                this.tryComments();
                useGlobalStore().trySuccessMessage('Comentário enviado.');
                return true;
            } catch (error) {
                useGlobalStore().tryIncomingErrors(error, page);
                return false;
            }
        },

        getCheckPercentage() {
            let w = 0;
            let t = 0;
            for (var i = 0; i < this.chapters.length; i++) {
                if(this.chapters[i].type === 'module'){
                    for (var l = 0; l < this.chapters[i].lessons.length; l++) {
                        t++;
                        if(this.chapters[i].lessons[l].watched) {
                            w++;
                        }
                    }
                } else {
                    t++;
                    if(this.chapters[i].watched){
                        w++
                    }
                }
            }



            let c = ((w * 100) / t);

            if(t === 0){
                c = 0;
            }
            this.percentage = Math.round(c);
        },

        getDuration(tMin) {
            let hours = (tMin / 60);
            let rhours = Math.floor(hours);
            let minutes = (hours - rhours) * 60;
            let rminutes = Math.round(minutes);
            let duration = null;
            if(rhours > 0) {
                duration = rhours + 'hrs';
                if(rminutes > 0) {
                    duration = duration + ' e ' + rminutes + 'min';
                }
            }else {
                duration = rminutes + 'min';
            }
            return duration;
        },

        getSelectedChapterId() {
            for (var i = 0; i < this.listLessons.length; i++) {
                if(this.listLessons[i].lesson == this.getSelectedLession()) {
                    return this.listLessons[i].chapter;
                }
            }
        },

        getSelectedLessonKey() {
            for (var i = 0; i < this.listLessons.length; i++) {
                if(this.listLessons[i].lesson == this.getSelectedLession()) {
                    return i;
                }
            }
        },

        getLessonId(i) {
            return this.listLessons[i].lesson;
        },

        getSelectedLession() {
            if(this.listLessons.length){
                return (this.selectedLesson.length === 0) ? this.listLessons[0].lesson : this.selectedLesson;
            }

        }
    }
});