<template>
    <router-link v-if="link && !target" :to="to" class="btn px-3" >
        <slot></slot>
    </router-link>

    <a v-else-if="link && target" :href="to" class="btn px-3" target="_blank">
        <slot></slot>
    </a>

    <button v-else :disabled="disabled == 1" :class="[!!loading ? 'loading' : 'px-3']" class="btn">
        <slot></slot>
        <div v-if="!!loading" class="loader"><strong></strong></div>
    </button>
</template>

<script>
export default {
    props: {
        link: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        to: {
            type: String,
            required: false,
            default: "/",
        },

        loading: {
            type: Boolean,
            required: false,
        },

        target: {
            type: String,
            required: false,
            default: "",
        }
    },
};
</script>

<style scoped>
a {
    color: #fff !important;
    text-decoration: none !important;
}

.btn {
    border-radius: 25px !important;
    font-size: 14px !important;
    padding: 8px;
}

.btn.btn-primary, a.btn-primary {
    background-color: #215eda !important;
}

.btn.btn-primary:hover, a.btn-primary:hover {
    background-color: #2750a3 !important;
}

.btn .loader {
  z-index: 9999;
  display: none;
  width: 100%;
  border-radius: 50px;
  text-align: center;
}

.btn .loader strong {
  display: inline-block;
  z-index: 2000;
  width: 25px;
  height: 25px;
  border: solid 3px transparent;
  border-top-color: #fff;
  border-left-color: #fff;
  border-radius: 100%;
  animation: pace-spinner 500ms linear infinite;
}

.btn.loading {
  padding: 7px 5px 7px 5px;
  font-size: 0 !important;
  width: 42px !important;
  margin: 0 auto;
  transition: width 0.5s ease-in-out;
  opacity: 1;
}

.btn.loading, .btn.loading .loader {
  display: block;
}

@keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>